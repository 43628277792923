import { RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import * as React from 'react';

import {
  HeadMeta,
  HomeHero,
  IClickToActionProps,
  IInfoLink,
  IMenuLink,
  ITileProps,
  Layout,
  MainHero,
  SmallHero,
} from '..';
import { LanguageContext } from '../../context/LanguageContext';
import { RichTextElements } from '../../utils/fragments/types';
import {
  IContentfulVacationBanner,
  ICustomGatsbyImageData,
  ILocation,
  IMetaTagField,
} from '../../utils/types';
import { RichTextRender } from '../RichTextRender';

export interface IContactPageComponentProps {
  menuLinks: IMenuLink[];
  infoLinks: IInfoLink[];
  title: string;
  headerType: string;
  language: 'De' | 'En';
  menuTitle?: string;
  subHeading?: string;
  featuredImage?: ICustomGatsbyImageData;
  staticLocation?: ILocation;
  meta?: IMetaTagField[];
  description?: { description: string };
  sectionId?: string;
  pageContent?: RenderRichTextData<RichTextElements>;
  tiles?: ITileProps[];
  clickToAction?: IClickToActionProps;
  vacationBanner?: IContentfulVacationBanner;
}

export const ContactPage: React.FunctionComponent<IContactPageComponentProps> = (
  props
) => {
  return (
    <LanguageContext.Provider value={props.language}>
      <Layout
        links={props.menuLinks}
        infoLinks={props.infoLinks}
        vacationBanner={props.vacationBanner}
      >
        <HeadMeta title={props.title} meta={props.meta} lang={props.language.toLowerCase()} />
        {
          {
            'Home Hero': (
              <HomeHero
                title={props.title}
                image={props.featuredImage}
                subTitle={props.subHeading}
              />
            ),
            'Small Hero': (
              <SmallHero
                title={props.title}
                featuredImage={props.featuredImage}
                subheading={props.subHeading}
                description={props.description?.description}
                location={props.staticLocation}
              />
            ),
            'Main Hero': (
              <MainHero
                title={props.title}
                image={props.featuredImage}
                description={props.description}
                sectionId={props.sectionId}
                location={props.staticLocation}
                tiles={props.tiles || null}
                clickToActionTile={props.clickToAction}
              />
            ),
          }[props.headerType]
        }
        {props.pageContent && <RichTextRender content={props.pageContent} />}
      </Layout>
    </LanguageContext.Provider>
  );
};
